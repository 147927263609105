.WEB {
    align-items: center;
    background-color: #ecf4ff;
    border: 1px none;
    display: flex;
    flex-direction: column;
    gap: 48px;
    min-width: 1440px;
    position: relative;
}

.WEB .HEADER {
    background-color: #ecf4ff;
    height: 128px;
    min-width: 1440px;
    mix-blend-mode: normal;
    position: relative;
}

.WEB .frame {
    align-items: center;
    display: flex;
    justify-content: space-between;
    left: 80px;
    position: relative;
    top: 33px;
    width: 1281px;
}

.WEB .logo {
    align-items: center;
    display: flex;
    gap: 32px;
    justify-content: center;
    position: relative;
    width: fit-content;
}

.WEB .logo-img {
    background-color: #24aee5;
    border-radius: 16px;
    height: 62px;
    min-width: 62px;
    position: relative;
}

.WEB .overlap-group {
    background-image: url(../../../static/img/vector-1-1.svg);
    background-size: 100% 100%;
    height: 35px;
    left: 13px;
    position: relative;
    top: 14px;
    width: 37px;
}

.WEB .vector {
    height: 19px;
    left: 13px;
    position: absolute;
    top: 8px;
    width: 14px;
}

.WEB .img {
    height: 3px;
    left: 18px;
    position: absolute;
    top: 13px;
    width: 4px;
}

.WEB .logo-text {
    height: 49.85px;
    min-width: 262.74px;
    position: relative;
}

.WEB .menu {
    align-items: flex-start;
    display: flex;
    gap: 24px;
    justify-content: flex-end;
    position: relative;
    width: fit-content;
}

.WEB .div-wrapper {
    align-items: center;
    border-radius: 16px;
    display: flex;
    gap: 10px;
    justify-content: flex-end;
    padding: 16px 32px;
    position: relative;
    width: fit-content;
}

.WEB .text-wrapper {
    color: #0f084b;
    font-family: "Kumbh Sans", Helvetica;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1px;
    position: relative;
    text-align: center;
    width: fit-content;
}

.WEB .div {
    align-items: center;
    background-color: #24aee5;
    border-radius: 16px;
    display: flex;
    gap: 10px;
    justify-content: flex-end;
    padding: 16px 32px;
    position: relative;
    width: fit-content;
}

.WEB .text-wrapper-2 {
    color: #ffffff;
    font-family: "Kumbh Sans", Helvetica;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1px;
    position: relative;
    text-align: center;
    width: fit-content;
}

.WEB .INTRO {
    background-color: #ecf4ff;
    box-shadow: 2px 22px 31px -14px #00000040;
    height: 722px;
    min-width: 1440px;
    position: relative;
}

.WEB .overlap {
    height: 668px;
    position: relative;
    top: 54px;
    width: 1364px;
}

.WEB .vector-2 {
    height: 557px;
    left: 735px;
    position: absolute;
    top: 0;
    width: 629px;
}

.WEB .seniors-are {
    color: #000000;
    font-family: "Kumbh Sans", Helvetica;
    font-size: 36px;
    font-weight: 400;
    height: 315px;
    left: 983px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 100px;
    width: 312px;
}

.WEB .frame-2 {
    align-items: flex-start;
    background-color: #24aee5;
    border-radius: 8px;
    display: flex;
    gap: 10px;
    left: 983px;
    padding: 10px 24px;
    position: absolute;
    top: 480px;
    width: fit-content;
}

.WEB .text-wrapper-3 {
    color: #f8fdff;
    font-family: "Kumbh Sans", Helvetica;
    font-size: 16px;
    font-weight: 700;
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1px;
    position: relative;
    text-align: center;
    width: fit-content;
}

.WEB .vector-3 {
    height: 522px;
    left: 0;
    position: absolute;
    top: 146px;
    width: 867px;
}

.WEB .girl-teaching-her {
    height: 602px;
    left: 0;
    object-fit: cover;
    position: absolute;
    top: 66px;
    width: 887px;
}

.WEB .h-1 {
    color: #0f084b;
    font-family: "Kumbh Sans", Helvetica;
    font-size: 36px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: normal;
    position: relative;
    width: fit-content;
}

.WEB .STEPS {
    align-items: center;
    display: flex;
    gap: 60px;
    height: 321px;
    padding: 0px 80px;
    position: relative;
    width: 1440px;
}

.WEB .STEP {
    border-radius: 16px;
    height: 274px;
    min-width: 275px;
    position: relative;
}

.WEB .overlap-group-2 {
    height: 300px;
    position: relative;
    top: -25px;
    width: 275px;
}

.WEB .rectangle {
    -webkit-backdrop-filter: blur(40px) brightness(100%);
    backdrop-filter: blur(40px) brightness(100%);
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0.2) 100%);
    border: 3px solid;
    border-color: transparent;
    border-image: linear-gradient(to bottom,
            rgba(255, 255, 255, 0.7) 0.43%,
            rgba(0, 182.33, 255, 0) 54.17%,
            rgba(0, 182.33, 255, 0.29) 100%) 1;
    border-radius: 16px;
    box-shadow: 0px 4px 24px -1px #00000040;
    height: 275px;
    left: 0;
    position: absolute;
    top: 25px;
    width: 275px;
}

.WEB .vector-4 {
    height: 91px;
    left: 77px;
    position: absolute;
    top: 78px;
    width: 118px;
}

.WEB .p {
    color: #0d0221;
    font-family: "Satoshi-Regular", Helvetica;
    font-size: 16px;
    font-weight: 400;
    height: 44px;
    left: 36px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    text-align: center;
    top: 202px;
    width: 200px;
}

.WEB .vector-5 {
    height: 56px;
    left: 111px;
    position: absolute;
    top: 96px;
    width: 50px;
}

.WEB .frame-3 {
    align-items: flex-start;
    background: linear-gradient(180deg, rgb(255, 255, 255) 0%, rgba(255, 255, 255, 0.39) 100%);
    border: 2px solid;
    border-color: transparent;
    border-image: linear-gradient(to bottom,
            rgba(255, 255, 255, 0.7) 0.43%,
            rgba(0, 182.33, 255, 0) 54.17%,
            rgba(0, 182.33, 255, 0.29) 100%) 1;
    border-radius: 16px;
    display: flex;
    gap: 10px;
    left: 93px;
    padding: 10px;
    position: absolute;
    top: 0;
    width: fit-content;
}

.WEB .text-wrapper-4 {
    color: #000000;
    font-family: "Kumbh Sans", Helvetica;
    font-size: 24px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: normal;
    margin-top: -2px;
    position: relative;
    text-align: center;
    width: fit-content;
}

.WEB .STEP-2 {
    -webkit-backdrop-filter: blur(40px) brightness(100%);
    backdrop-filter: blur(40px) brightness(100%);
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0.2) 100%);
    border-radius: 16px;
    box-shadow: 0px 4px 24px -1px #00000040;
    height: 275px;
    min-width: 275px;
    position: relative;
}

.WEB .text-wrapper-5 {
    color: #0d0221;
    font-family: "Satoshi-Regular", Helvetica;
    font-size: 16px;
    font-weight: 400;
    height: 44px;
    left: 37px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    text-align: center;
    top: 178px;
    width: 200px;
}

.WEB .vector-wrapper {
    background-image: url(../../../static/img/vector-12.svg);
    background-size: 100% 100%;
    height: 98px;
    left: 73px;
    position: absolute;
    top: 48px;
    width: 126px;
}

.WEB .vector-6 {
    height: 56px;
    left: 36px;
    position: absolute;
    top: 24px;
    width: 56px;
}

.WEB .frame-4 {
    align-items: flex-start;
    background: linear-gradient(180deg, rgb(255, 255, 255) 0%, rgba(255, 255, 255, 0.39) 100%);
    border: 2px solid;
    border-color: transparent;
    border-image: linear-gradient(to bottom,
            rgba(255, 255, 255, 0.7) 0.43%,
            rgba(0, 182.33, 255, 0) 54.17%,
            rgba(0, 182.33, 255, 0.29) 100%) 1;
    border-radius: 16px;
    display: flex;
    gap: 10px;
    left: 91px;
    padding: 10px;
    position: absolute;
    top: -25px;
    width: fit-content;
}

.WEB .text-wrapper-6 {
    color: #0d0221;
    font-family: "Satoshi-Regular", Helvetica;
    font-size: 16px;
    font-weight: 400;
    height: 66px;
    left: 38px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    text-align: center;
    top: 178px;
    width: 200px;
}

.WEB .frame-5 {
    align-items: flex-start;
    background: linear-gradient(180deg, rgb(255, 255, 255) 0%, rgba(255, 255, 255, 0.39) 100%);
    border: 2px solid;
    border-color: transparent;
    border-image: linear-gradient(to bottom,
            rgba(255, 255, 255, 0.7) 0.43%,
            rgba(0, 182.33, 255, 0) 54.17%,
            rgba(0, 182.33, 255, 0.29) 100%) 1;
    border-radius: 16px;
    display: flex;
    gap: 10px;
    left: 93px;
    padding: 10px;
    position: absolute;
    top: -25px;
    width: fit-content;
}

.WEB .overlap-2 {
    background-image: url(../../../static/img/vector-13-1.svg);
    background-size: 100% 100%;
    height: 99px;
    left: 76px;
    position: absolute;
    top: 49px;
    width: 124px;
}

.WEB .vector-7 {
    height: 32px;
    left: 16px;
    position: absolute;
    top: 15px;
    width: 37px;
}

.WEB .vector-8 {
    height: 44px;
    left: 61px;
    position: absolute;
    top: 40px;
    width: 28px;
}

.WEB .text-wrapper-7 {
    color: #0d0221;
    font-family: "Satoshi-Regular", Helvetica;
    font-size: 16px;
    font-weight: 400;
    height: 66px;
    left: 39px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    text-align: center;
    top: 203px;
    width: 200px;
}

.WEB .frame-6 {
    align-items: flex-start;
    background: linear-gradient(180deg, rgb(255, 255, 255) 0%, rgba(255, 255, 255, 0.39) 100%);
    border: 2px solid;
    border-color: transparent;
    border-image: linear-gradient(to bottom,
            rgba(255, 255, 255, 0.7) 0.43%,
            rgba(0, 182.33, 255, 0) 54.17%,
            rgba(0, 182.33, 255, 0.29) 100%) 1;
    border-radius: 16px;
    display: flex;
    gap: 10px;
    left: 92px;
    padding: 10px;
    position: absolute;
    top: 0;
    width: fit-content;
}

.WEB .vector-9 {
    height: 99px;
    left: 76px;
    position: absolute;
    top: 78px;
    width: 124px;
}

.WEB .vector-10 {
    height: 50px;
    left: 109px;
    position: absolute;
    top: 102px;
    width: 56px;
}

.WEB .CALENDAR {
    align-items: center;
    display: flex;
    gap: 48px;
    height: 600px;
    justify-content: center;
    position: relative;
    width: 1440px;
}

.WEB .frame-7 {
    align-items: center;
    display: flex;
    gap: 80px;
    position: relative;
    width: fit-content;
}

.WEB .component {
    height: 490.43px;
    margin-bottom: -8.25px;
    margin-left: -27px;
    margin-top: -4.25px;
    min-width: 509px;
    position: relative;
}

.WEB .HOURS {
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 17.33px;
    height: 432px;
    justify-content: center;
    position: relative;
    width: fit-content;
}

.WEB .vector-11 {
    height: 18.23px;
    min-width: 32.38px;
    position: relative;
}

.WEB .frame-8 {
    align-items: center;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0.2) 100%);
    border: 2px solid;
    border-color: transparent;
    border-image: linear-gradient(to bottom,
            rgba(255, 255, 255, 0.7) 0.43%,
            rgba(0, 182.33, 255, 0) 44.79%,
            rgba(0, 182.33, 255, 0.29) 100%) 1;
    border-radius: 11.56px;
    box-shadow: 2px 2px 8px 2px #00000040;
    display: flex;
    gap: 7.22px;
    justify-content: center;
    padding: 17.33px 192.11px;
    position: relative;
    width: 196px;
}

.WEB .text-wrapper-8 {
    color: #26408b;
    font-family: "Lexend", Helvetica;
    font-size: 17.3px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: normal;
    margin-left: -116.11px;
    margin-right: -116.11px;
    margin-top: -2px;
    position: relative;
    width: fit-content;
}

.WEB .text-wrapper-9 {
    color: #26408b;
    font-family: "Lexend", Helvetica;
    font-size: 17.3px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: normal;
    margin-left: -115.61px;
    margin-right: -115.61px;
    margin-top: -2px;
    position: relative;
    width: fit-content;
}

.WEB .frame-9 {
    align-items: center;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0.2) 100%);
    border: 2px solid;
    border-color: transparent;
    border-image: linear-gradient(to bottom,
            rgba(255, 255, 255, 0.7) 0.43%,
            rgba(0, 182.33, 255, 0) 44.79%,
            rgba(0, 182.33, 255, 0.29) 100%) 1;
    border-radius: 11.56px;
    box-shadow: 2px 2px 8px 2px #00000040;
    display: flex;
    gap: 7.22px;
    justify-content: center;
    padding: 17.33px 192.11px;
    position: relative;
    width: 194px;
}

.WEB .frame-10 {
    align-items: center;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0.2) 100%);
    border: 2px solid;
    border-color: transparent;
    border-image: linear-gradient(to bottom,
            rgba(255, 255, 255, 0.7) 6.85%,
            rgba(0, 182.33, 255, 0) 44.79%,
            rgba(0, 182.33, 255, 0.29) 100%) 1;
    border-radius: 11.56px;
    box-shadow: 2px 2px 8px 2px #00000040;
    display: flex;
    gap: 7.22px;
    justify-content: center;
    padding: 17.33px 192.11px;
    position: relative;
    width: 194px;
}

.WEB .INFO {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    gap: 32px;
    justify-content: center;
    position: relative;
    width: fit-content;
}

.WEB .group {
    height: 86.6px;
    min-width: 122.07px;
    position: relative;
}

.WEB .overlap-group-3 {
    height: 84px;
    position: relative;
    top: 1px;
    width: 121px;
}

.WEB .vector-12 {
    height: 84px;
    left: 33px;
    position: absolute;
    top: 0;
    width: 88px;
}

.WEB .frame-11 {
    align-items: flex-start;
    background-color: #ffffff;
    border: 2px solid;
    border-color: transparent;
    border-image: linear-gradient(to bottom,
            rgba(255, 255, 255, 0.7) 0.43%,
            rgba(0, 182.33, 255, 0) 54.17%,
            rgba(0, 182.33, 255, 0.29) 100%) 1;
    border-radius: 16px;
    display: flex;
    gap: 10px;
    left: 0;
    padding: 10px;
    position: absolute;
    top: 8px;
    width: fit-content;
}

.WEB .text-wrapper-10 {
    color: #0d0221;
    font-family: "Satoshi-Regular", Helvetica;
    font-size: 36px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: normal;
    position: relative;
    width: 481px;
}

.WEB .frame-12 {
    align-items: center;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0.2) 100%);
    border: 2px solid;
    border-color: transparent;
    border-image: linear-gradient(to bottom,
            rgba(255, 255, 255, 0.7) 0.43%,
            rgba(0, 182.33, 255, 0) 44.79%,
            rgba(0, 182.33, 255, 0.29) 100%) 1;
    border-radius: 11.56px;
    box-shadow: 2px 2px 8px 2px #00000040;
    display: flex;
    gap: 7.22px;
    padding: 17.33px 32px;
    position: relative;
    width: 474px;
}

.WEB .text-wrapper-11 {
    color: #26408b;
    font-family: "Lexend", Helvetica;
    font-size: 17.3px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: normal;
    margin-top: -2px;
    position: relative;
    width: fit-content;
}

.WEB .next-step {
    color: #ffffff;
    font-family: "Kumbh Sans", Helvetica;
    font-size: 16px;
    font-weight: 700;
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1px;
    position: relative;
    text-align: center;
    width: fit-content;
}

.WEB .FOOTER {
    background-color: #ecf4ff;
    height: 176px;
    min-width: 1440px;
    mix-blend-mode: normal;
    position: relative;
}

.WEB .frame-13 {
    align-items: center;
    display: flex;
    gap: 475px;
    left: 80px;
    position: relative;
    top: 33px;
    width: 1280px;
}

.WEB .COPYRIGHT-wrapper {
    align-items: center;
    display: flex;
    position: relative;
    width: fit-content;
}

.WEB .COPYRIGHT {
    align-items: center;
    display: flex;
    gap: 16px;
    height: 48px;
    position: relative;
    width: fit-content;
}

.WEB .image {
    height: 97px;
    margin-bottom: -24.5px;
    margin-top: -24.5px;
    min-width: 137px;
    object-fit: cover;
    position: relative;
}

.WEB .frame-14 {
    align-items: center;
    display: flex;
    gap: 8px;
    position: relative;
    width: fit-content;
}

.WEB .group-2 {
    height: 16px;
    min-width: 16px;
    position: relative;
}

.WEB .digiplumber-s-l {
    color: #0d0221;
    font-family: "Satoshi-Regular", Helvetica;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1px;
    position: relative;
    width: fit-content;
}

.WEB .SN {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    gap: 23px;
    position: relative;
    width: fit-content;
}

.WEB .text-wrapper-12 {
    color: #0d0221;
    font-family: "Satoshi-Regular", Helvetica;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1px;
    position: relative;
    width: 473px;
}

.WEB .frame-15 {
    align-items: center;
    display: flex;
    gap: 16px;
    position: relative;
    width: fit-content;
}

.WEB .frame-16 {
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 8px;
    position: relative;
    width: 69px;
}

.WEB .facebook-rounded {
    background-image: url(../../../static/img/container-4.svg);
    background-size: 100% 100%;
    height: 40px;
    min-width: 40px;
    position: relative;
}

.WEB .text-wrapper-13 {
    color: #0d0221;
    font-family: "Satoshi-Regular", Helvetica;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: normal;
    position: relative;
    width: fit-content;
}

.WEB .frame-17 {
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 8px;
    position: relative;
    width: 78px;
}

.WEB .instagram-rounded {
    background-image: url(../../../static/img/container-3.svg);
    background-size: 100% 100%;
    height: 40px;
    min-width: 39.98px;
    position: relative;
}

.WEB .linkedin-rounded {
    background-image: url(../../../static/img/container-2.svg);
    background-size: 100% 100%;
    height: 40px;
    min-width: 40px;
    position: relative;
}

.WEB .youtube-rounded {
    background-image: url(../../../static/img/container-1.svg);
    background-size: 100% 100%;
    height: 40px;
    min-width: 40px;
    position: relative;
}

.WEB .whatsapp-rounded {
    background-image: url(../../../static/img/container.svg);
    background-size: 100% 100%;
    height: 40px;
    min-width: 40px;
    position: relative;
}