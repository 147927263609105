.TABLET {
    background-color: #ecf4ff;
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
}

.TABLET .TABLET-wrapper {
    background-color: #ecf4ff;
    border: 1px none;
    height: 2615px;
    width: 834px;
}

.TABLET .div {
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 48px;
    height: 2615px;
    position: relative;
    width: fit-content;
}

.TABLET .HEADER {
    background-color: #ecf4ff;
    height: 128px;
    min-width: 832px;
    mix-blend-mode: normal;
    position: relative;
}

.TABLET .frame {
    align-items: center;
    display: flex;
    gap: 230px;
    left: 33px;
    position: relative;
    top: 33px;
    width: 769px;
}

.TABLET .LOGO {
    align-items: center;
    display: flex;
    gap: 32px;
    justify-content: center;
    position: relative;
    width: fit-content;
}

.TABLET .logo-img {
    background-color: #24aee5;
    border-radius: 16px;
    height: 62px;
    min-width: 62px;
    position: relative;
}

.TABLET .overlap-group {
    background-image: url(../../../static/img/vector-1-1.svg);
    background-size: 100% 100%;
    height: 35px;
    left: 13px;
    position: relative;
    top: 14px;
    width: 37px;
}

.TABLET .vector {
    height: 19px;
    left: 13px;
    position: absolute;
    top: 8px;
    width: 14px;
}

.TABLET .img {
    height: 3px;
    left: 18px;
    position: absolute;
    top: 13px;
    width: 4px;
}

.TABLET .logo-text {
    height: 49.85px;
    min-width: 262.74px;
    position: relative;
}

.TABLET .SART {
    align-items: flex-start;
    display: flex;
    gap: 24px;
    justify-content: flex-end;
    margin-right: -0.74px;
    position: relative;
    width: fit-content;
}

.TABLET .test-now-wrapper {
    align-items: center;
    background-color: #24aee5;
    border-radius: 16px;
    display: flex;
    gap: 10px;
    justify-content: flex-end;
    padding: 16px 32px;
    position: relative;
    width: fit-content;
}

.TABLET .test-now {
    color: #ffffff;
    font-family: "Kumbh Sans", Helvetica;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1px;
    position: relative;
    text-align: center;
    width: fit-content;
}

.TABLET .INTRO {
    background-color: #ecf4ff;
    box-shadow: 1.37px 15.11px 21.3px -9.62px #00000040;
    height: 496px;
    min-width: 832px;
    position: relative;
}

.TABLET .overlap {
    height: 477px;
    position: relative;
    top: 19px;
    width: 812px;
}

.TABLET .vector-2 {
    height: 363px;
    left: 389px;
    position: absolute;
    top: 0;
    width: 423px;
}

.TABLET .seniors-are {
    color: #000000;
    font-family: "Kumbh Sans", Helvetica;
    font-size: 24.7px;
    font-weight: 400;
    height: 217px;
    left: 569px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 47px;
    width: 197px;
}

.TABLET .div-wrapper {
    align-items: flex-start;
    background-color: #24aee5;
    border-radius: 5.5px;
    display: flex;
    gap: 6.87px;
    left: 569px;
    padding: 6.87px 16.49px;
    position: absolute;
    top: 290px;
    width: fit-content;
}

.TABLET .text-wrapper {
    color: #f8fdff;
    font-family: "Kumbh Sans", Helvetica;
    font-size: 11px;
    font-weight: 700;
    letter-spacing: 0;
    line-height: normal;
    margin-top: -0.69px;
    position: relative;
    text-align: center;
    width: fit-content;
}

.TABLET .vector-3 {
    height: 359px;
    left: 0;
    position: absolute;
    top: 118px;
    width: 502px;
}

.TABLET .girl-teaching-her {
    height: 414px;
    left: 0;
    object-fit: cover;
    position: absolute;
    top: 64px;
    width: 516px;
}

.TABLET .p {
    color: #0f084b;
    font-family: "Kumbh Sans", Helvetica;
    font-size: 32px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: normal;
    position: relative;
    width: fit-content;
}

.TABLET .STEP {
    align-items: center;
    display: flex;
    gap: 60px;
    height: 321px;
    justify-content: center;
    padding: 0px 80px;
    position: relative;
    width: 834px;
}

.TABLET .overlap-group-wrapper {
    border-radius: 16px;
    height: 274px;
    min-width: 275px;
    position: relative;
}

.TABLET .overlap-group-2 {
    height: 300px;
    position: relative;
    top: -25px;
    width: 275px;
}

.TABLET .rectangle {
    -webkit-backdrop-filter: blur(40px) brightness(100%);
    backdrop-filter: blur(40px) brightness(100%);
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0.2) 100%);
    border: 3px solid;
    border-color: transparent;
    border-image: linear-gradient(to bottom,
            rgba(255, 255, 255, 0.7) 0.43%,
            rgba(0, 182.33, 255, 0) 54.17%,
            rgba(0, 182.33, 255, 0.29) 100%) 1;
    border-radius: 16px;
    box-shadow: 0px 4px 24px -1px #00000040;
    height: 275px;
    left: 0;
    position: absolute;
    top: 25px;
    width: 275px;
}

.TABLET .vector-4 {
    height: 91px;
    left: 77px;
    position: absolute;
    top: 78px;
    width: 118px;
}

.TABLET .text-wrapper-2 {
    color: #0d0221;
    font-family: "Satoshi-Regular", Helvetica;
    font-size: 16px;
    font-weight: 400;
    height: 44px;
    left: 36px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    text-align: center;
    top: 202px;
    width: 200px;
}

.TABLET .vector-5 {
    height: 56px;
    left: 111px;
    position: absolute;
    top: 96px;
    width: 50px;
}

.TABLET .frame-2 {
    align-items: flex-start;
    background: linear-gradient(180deg, rgb(255, 255, 255) 0%, rgba(255, 255, 255, 0.39) 100%);
    border: 2px solid;
    border-color: transparent;
    border-image: linear-gradient(to bottom,
            rgba(255, 255, 255, 0.7) 0.43%,
            rgba(0, 182.33, 255, 0) 54.17%,
            rgba(0, 182.33, 255, 0.29) 100%) 1;
    border-radius: 16px;
    display: flex;
    gap: 10px;
    left: 93px;
    padding: 10px;
    position: absolute;
    top: 0;
    width: fit-content;
}

.TABLET .text-wrapper-3 {
    color: #000000;
    font-family: "Kumbh Sans", Helvetica;
    font-size: 24px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: normal;
    margin-top: -2px;
    position: relative;
    text-align: center;
    width: fit-content;
}

.TABLET .STEP-2 {
    -webkit-backdrop-filter: blur(40px) brightness(100%);
    backdrop-filter: blur(40px) brightness(100%);
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0.2) 100%);
    border-radius: 16px;
    box-shadow: 0px 4px 24px -1px #00000040;
    height: 275px;
    min-width: 275px;
    position: relative;
}

.TABLET .text-wrapper-4 {
    color: #0d0221;
    font-family: "Satoshi-Regular", Helvetica;
    font-size: 16px;
    font-weight: 400;
    height: 44px;
    left: 37px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    text-align: center;
    top: 178px;
    width: 200px;
}

.TABLET .vector-wrapper {
    background-image: url(../../../static/img/vector-12-1.svg);
    background-size: 100% 100%;
    height: 98px;
    left: 73px;
    position: absolute;
    top: 48px;
    width: 126px;
}

.TABLET .vector-6 {
    height: 56px;
    left: 36px;
    position: absolute;
    top: 24px;
    width: 56px;
}

.TABLET .frame-3 {
    align-items: flex-start;
    background: linear-gradient(180deg, rgb(255, 255, 255) 0%, rgba(255, 255, 255, 0.39) 100%);
    border: 2px solid;
    border-color: transparent;
    border-image: linear-gradient(to bottom,
            rgba(255, 255, 255, 0.7) 0.43%,
            rgba(0, 182.33, 255, 0) 54.17%,
            rgba(0, 182.33, 255, 0.29) 100%) 1;
    border-radius: 16px;
    display: flex;
    gap: 10px;
    left: 91px;
    padding: 10px;
    position: absolute;
    top: -25px;
    width: fit-content;
}

.TABLET .text-wrapper-5 {
    color: #000000;
    font-family: "Kumbh Sans", Helvetica;
    font-size: 24px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: normal;
    margin-top: -2px;
    position: relative;
    text-align: center;
    width: fit-content;
}

.TABLET .STEP-3 {
    align-items: center;
    display: flex;
    gap: 60px;
    height: 321px;
    justify-content: center;
    padding: 0px 80px;
    position: relative;
    width: 834px;
}

.TABLET .STEP-4 {
    -webkit-backdrop-filter: blur(40px) brightness(100%);
    backdrop-filter: blur(40px) brightness(100%);
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0.2) 100%);
    border-radius: 16px;
    box-shadow: 0px 4px 24px -1px #00000040;
    height: 275px;
    min-width: 275px;
    position: relative;
}

.TABLET .text-wrapper-6 {
    color: #0d0221;
    font-family: "Satoshi-Regular", Helvetica;
    font-size: 16px;
    font-weight: 400;
    height: 88px;
    left: 38px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    text-align: center;
    top: 166px;
    width: 200px;
}

.TABLET .frame-4 {
    align-items: flex-start;
    background: linear-gradient(180deg, rgb(255, 255, 255) 0%, rgba(255, 255, 255, 0.39) 100%);
    border: 2px solid;
    border-color: transparent;
    border-image: linear-gradient(to bottom,
            rgba(255, 255, 255, 0.7) 0.43%,
            rgba(0, 182.33, 255, 0) 54.17%,
            rgba(0, 182.33, 255, 0.29) 100%) 1;
    border-radius: 16px;
    display: flex;
    gap: 10px;
    left: 93px;
    padding: 10px;
    position: absolute;
    top: -25px;
    width: fit-content;
}

.TABLET .text-wrapper-7 {
    color: #000000;
    font-family: "Kumbh Sans", Helvetica;
    font-size: 24px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: normal;
    margin-top: -2px;
    position: relative;
    text-align: center;
    width: fit-content;
}

.TABLET .overlap-group-3 {
    background-image: url(../../../static/img/vector-13-1.svg);
    background-size: 100% 100%;
    height: 99px;
    left: 76px;
    position: absolute;
    top: 49px;
    width: 124px;
}

.TABLET .vector-7 {
    height: 32px;
    left: 16px;
    position: absolute;
    top: 15px;
    width: 37px;
}

.TABLET .vector-8 {
    height: 44px;
    left: 61px;
    position: absolute;
    top: 40px;
    width: 28px;
}

.TABLET .overlap-wrapper {
    border-radius: 16px;
    height: 274px;
    min-width: 275px;
    position: relative;
}

.TABLET .overlap-2 {
    height: 300px;
    position: relative;
    top: -25px;
    width: 275px;
}

.TABLET .rectangle-2 {
    -webkit-backdrop-filter: blur(40px) brightness(100%);
    backdrop-filter: blur(40px) brightness(100%);
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0.2) 100%);
    border: 3px solid;
    border-color: transparent;
    border-image: linear-gradient(to bottom,
            rgba(255, 255, 255, 0.7) 0.43%,
            rgba(0, 182.33, 255, 0) 54.17%,
            rgba(0, 182.33, 255, 0.29) 100%) 1;
    border-radius: 16px;
    box-shadow: 0px 4px 24px -1px #00000040;
    height: 275px;
    left: 0;
    position: absolute;
    top: 25px;
    width: 275px;
}

.TABLET .text-wrapper-8 {
    color: #0d0221;
    font-family: "Satoshi-Regular", Helvetica;
    font-size: 16px;
    font-weight: 400;
    height: 66px;
    left: 39px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    text-align: center;
    top: 203px;
    width: 200px;
}

.TABLET .frame-5 {
    align-items: flex-start;
    background: linear-gradient(180deg, rgb(255, 255, 255) 0%, rgba(255, 255, 255, 0.39) 100%);
    border: 2px solid;
    border-color: transparent;
    border-image: linear-gradient(to bottom,
            rgba(255, 255, 255, 0.7) 0.43%,
            rgba(0, 182.33, 255, 0) 54.17%,
            rgba(0, 182.33, 255, 0.29) 100%) 1;
    border-radius: 16px;
    display: flex;
    gap: 10px;
    left: 92px;
    padding: 10px;
    position: absolute;
    top: 0;
    width: fit-content;
}

.TABLET .text-wrapper-9 {
    color: #000000;
    font-family: "Kumbh Sans", Helvetica;
    font-size: 24px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: normal;
    margin-top: -2px;
    position: relative;
    text-align: center;
    width: fit-content;
}

.TABLET .vector-9 {
    height: 99px;
    left: 76px;
    position: absolute;
    top: 78px;
    width: 124px;
}

.TABLET .vector-10 {
    height: 50px;
    left: 109px;
    position: absolute;
    top: 102px;
    width: 56px;
}

.TABLET .INFO {
    align-items: center;
    display: flex;
    gap: 80px;
    position: relative;
    width: 727px;
}

.TABLET .frame-6 {
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 48px;
    justify-content: center;
    position: relative;
    width: fit-content;
}

.TABLET .h-1 {
    color: #0d0221;
    font-family: "Satoshi-Regular", Helvetica;
    font-size: 36px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1px;
    position: relative;
    width: 615px;
}

.TABLET .frame-7 {
    align-items: flex-start;
    display: flex;
    gap: 48px;
    position: relative;
    width: 727px;
}

.TABLET .frame-8 {
    align-items: center;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0.2) 100%);
    border: 2px solid;
    border-color: transparent;
    border-image: linear-gradient(to bottom,
            rgba(255, 255, 255, 0.7) 0.43%,
            rgba(0, 182.33, 255, 0) 44.79%,
            rgba(0, 182.33, 255, 0.29) 100%) 1;
    border-radius: 11.56px;
    box-shadow: 2px 2px 8px 2px #00000040;
    display: flex;
    gap: 7.22px;
    height: 57px;
    padding: 17.33px 32px;
    position: relative;
    width: 335px;
}

.TABLET .text-wrapper-10 {
    color: #26408b;
    font-family: "Lexend", Helvetica;
    font-size: 17.3px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1.83px;
    position: relative;
    width: fit-content;
}

.TABLET .frame-9 {
    align-items: center;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0.2) 100%);
    border: 2px solid;
    border-color: transparent;
    border-image: linear-gradient(to bottom,
            rgba(255, 255, 255, 0.7) 0.43%,
            rgba(0, 182.33, 255, 0) 44.79%,
            rgba(0, 182.33, 255, 0.29) 100%) 1;
    border-radius: 11.56px;
    box-shadow: 2px 2px 8px 2px #00000040;
    display: flex;
    gap: 7.22px;
    padding: 17.33px 32px;
    position: relative;
    width: 344px;
}

.TABLET .text-wrapper-11 {
    color: #26408b;
    font-family: "Lexend", Helvetica;
    font-size: 17.3px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: normal;
    margin-top: -2px;
    position: relative;
    width: fit-content;
}

.TABLET .CALENDAR {
    align-items: center;
    display: flex;
    gap: 80px;
    position: relative;
    width: fit-content;
}

.TABLET .component {
    height: 490.43px;
    margin-bottom: -31px;
    margin-left: -27px;
    margin-top: -27px;
    min-width: 509px;
    position: relative;
}

.TABLET .frame-10 {
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 17.33px;
    height: 432px;
    justify-content: center;
    position: relative;
    width: fit-content;
}

.TABLET .vector-11 {
    height: 18.23px;
    min-width: 32.38px;
    position: relative;
}

.TABLET .frame-11 {
    align-items: center;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0.2) 100%);
    border: 2px solid;
    border-color: transparent;
    border-image: linear-gradient(to bottom,
            rgba(255, 255, 255, 0.7) 0.43%,
            rgba(0, 182.33, 255, 0) 44.79%,
            rgba(0, 182.33, 255, 0.29) 100%) 1;
    border-radius: 11.56px;
    box-shadow: 2px 2px 8px 2px #00000040;
    display: flex;
    gap: 7.22px;
    justify-content: center;
    padding: 17.33px 192.11px;
    position: relative;
    width: 196px;
}

.TABLET .text-wrapper-12 {
    color: #26408b;
    font-family: "Lexend", Helvetica;
    font-size: 17.3px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: normal;
    margin-left: -116.11px;
    margin-right: -116.11px;
    margin-top: -2px;
    position: relative;
    width: fit-content;
}

.TABLET .frame-12 {
    align-items: center;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0.2) 100%);
    border: 2px solid;
    border-color: transparent;
    border-image: linear-gradient(to bottom,
            rgba(255, 255, 255, 0.7) 0.43%,
            rgba(0, 182.33, 255, 0) 44.79%,
            rgba(0, 182.33, 255, 0.29) 100%) 1;
    border-radius: 11.56px;
    box-shadow: 2px 2px 8px 2px #00000040;
    display: flex;
    gap: 7.22px;
    justify-content: center;
    padding: 17.33px 192.11px;
    position: relative;
    width: 196px;
}

.TABLET .text-wrapper-13 {
    color: #26408b;
    font-family: "Lexend", Helvetica;
    font-size: 17.3px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: normal;
    margin-left: -115.61px;
    margin-right: -115.61px;
    margin-top: -2px;
    position: relative;
    width: fit-content;
}

.TABLET .frame-13 {
    align-items: center;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0.2) 100%);
    border: 2px solid;
    border-color: transparent;
    border-image: linear-gradient(to bottom,
            rgba(255, 255, 255, 0.7) 0.43%,
            rgba(0, 182.33, 255, 0) 44.79%,
            rgba(0, 182.33, 255, 0.29) 100%) 1;
    border-radius: 11.56px;
    box-shadow: 2px 2px 8px 2px #00000040;
    display: flex;
    gap: 7.22px;
    justify-content: center;
    padding: 17.33px 192.11px;
    position: relative;
    width: 196px;
}

.TABLET .text-wrapper-14 {
    color: #26408b;
    font-family: "Lexend", Helvetica;
    font-size: 17.3px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: normal;
    margin-left: -115.61px;
    margin-right: -115.61px;
    margin-top: -2px;
    position: relative;
    width: fit-content;
}

.TABLET .frame-14 {
    align-items: center;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0.2) 100%);
    border: 2px solid;
    border-color: transparent;
    border-image: linear-gradient(to bottom,
            rgba(255, 255, 255, 0.7) 0.43%,
            rgba(0, 182.33, 255, 0) 44.79%,
            rgba(0, 182.33, 255, 0.29) 100%) 1;
    border-radius: 11.56px;
    box-shadow: 2px 2px 8px 2px #00000040;
    display: flex;
    gap: 7.22px;
    justify-content: center;
    padding: 17.33px 192.11px;
    position: relative;
    width: 194px;
}

.TABLET .text-wrapper-15 {
    color: #26408b;
    font-family: "Lexend", Helvetica;
    font-size: 17.3px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: normal;
    margin-left: -115.61px;
    margin-right: -115.61px;
    margin-top: -2px;
    position: relative;
    width: fit-content;
}

.TABLET .frame-15 {
    align-items: center;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0.2) 100%);
    border: 2px solid;
    border-color: transparent;
    border-image: linear-gradient(to bottom,
            rgba(255, 255, 255, 0.7) 6.85%,
            rgba(0, 182.33, 255, 0) 44.79%,
            rgba(0, 182.33, 255, 0.29) 100%) 1;
    border-radius: 11.56px;
    box-shadow: 2px 2px 8px 2px #00000040;
    display: flex;
    gap: 7.22px;
    justify-content: center;
    padding: 17.33px 192.11px;
    position: relative;
    width: 194px;
}

.TABLET .text-wrapper-16 {
    color: #26408b;
    font-family: "Lexend", Helvetica;
    font-size: 17.3px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: normal;
    margin-left: -115.61px;
    margin-right: -115.61px;
    margin-top: -2px;
    position: relative;
    width: fit-content;
}

.TABLET .vector-12 {
    height: 18.23px;
    min-width: 32.38px;
    position: relative;
}

.TABLET .START {
    align-items: center;
    background-color: #24aee5;
    border-radius: 16px;
    display: flex;
    gap: 10px;
    justify-content: center;
    padding: 16px 32px;
    position: relative;
    width: 194px;
}

.TABLET .test-now {
    color: #ffffff;
    font-family: "Kumbh Sans", Helvetica;
    font-size: 16px;
    font-weight: 700;
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1px;
    position: relative;
    text-align: center;
    width: fit-content;
}

.TABLET .FOOTER {
    align-items: center;
    background-color: transparent;
    display: flex;
    flex-direction: column;
    gap: 32px;
    height: auto;
    position: relative;
    width: fit-content;
}

.TABLET .SN {
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 23px;
    position: relative;
    width: fit-content;
}

.TABLET .text-wrapper-17 {
    color: #0d0221;
    font-family: "Satoshi-Regular", Helvetica;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1px;
    position: relative;
    text-align: center;
    width: 473px;
}

.TABLET .frame-16 {
    align-items: center;
    display: flex;
    gap: 16px;
    position: relative;
    width: fit-content;
}

.TABLET .frame-17 {
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 8px;
    position: relative;
    width: 69px;
}

.TABLET .facebook-rounded {
    background-image: url(../../../static/img/container.svg);
    background-size: 100% 100%;
    height: 40px;
    min-width: 40px;
    position: relative;
}

.TABLET .text-wrapper-18 {
    color: #0d0221;
    font-family: "Satoshi-Regular", Helvetica;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: normal;
    position: relative;
    width: fit-content;
}

.TABLET .frame-18 {
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 8px;
    position: relative;
    width: 78px;
}

.TABLET .instagram-rounded {
    background-image: url(../../../static/img/container-1.svg);
    background-size: 100% 100%;
    height: 40px;
    min-width: 39.98px;
    position: relative;
}

.TABLET .text-wrapper-19 {
    color: #0d0221;
    font-family: "Satoshi-Regular", Helvetica;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: normal;
    position: relative;
    width: fit-content;
}

.TABLET .frame-19 {
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 8px;
    position: relative;
    width: 78px;
}

.TABLET .linkedin-rounded {
    background-image: url(../../../static/img/container-2.svg);
    background-size: 100% 100%;
    height: 40px;
    min-width: 40px;
    position: relative;
}

.TABLET .text-wrapper-20 {
    color: #0d0221;
    font-family: "Satoshi-Regular", Helvetica;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: normal;
    position: relative;
    width: fit-content;
}

.TABLET .frame-20 {
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 8px;
    position: relative;
    width: 78px;
}

.TABLET .youtube-rounded {
    background-image: url(../../../static/img/container-3.svg);
    background-size: 100% 100%;
    height: 40px;
    min-width: 40px;
    position: relative;
}

.TABLET .text-wrapper-21 {
    color: #0d0221;
    font-family: "Satoshi-Regular", Helvetica;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: normal;
    position: relative;
    width: fit-content;
}

.TABLET .frame-21 {
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 8px;
    position: relative;
    width: 78px;
}

.TABLET .whatsapp-rounded {
    background-image: url(../../../static/img/container-4.svg);
    background-size: 100% 100%;
    height: 40px;
    min-width: 40px;
    position: relative;
}

.TABLET .text-wrapper-22 {
    color: #0d0221;
    font-family: "Satoshi-Regular", Helvetica;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: normal;
    position: relative;
    width: fit-content;
}

.TABLET .COPYRIGHT {
    align-items: center;
    display: flex;
    gap: 16px;
    height: 48px;
    position: relative;
    width: fit-content;
}

.TABLET .logo-img-wrapper {
    height: 48px;
    min-width: 48px;
    position: relative;
}

.TABLET .logo-img-2 {
    background-color: #24aee5;
    border-radius: 16px;
    height: 48px;
    width: 48px;
}

.TABLET .overlap-group-4 {
    background-image: url(../../../static/img/vector-1-2.svg);
    background-size: 100% 100%;
    height: 27px;
    left: 10px;
    position: relative;
    top: 11px;
    width: 29px;
}

.TABLET .vector-13 {
    height: 15px;
    left: 10px;
    position: absolute;
    top: 6px;
    width: 11px;
}

.TABLET .vector-14 {
    height: 3px;
    left: 14px;
    position: absolute;
    top: 10px;
    width: 3px;
}

.TABLET .frame-22 {
    align-items: center;
    display: flex;
    gap: 8px;
    position: relative;
    width: fit-content;
}

.TABLET .group {
    height: 16px;
    min-width: 16px;
    position: relative;
}

.TABLET .text-wrapper-23 {
    color: #0d0221;
    font-family: "Satoshi-Regular", Helvetica;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1px;
    position: relative;
    width: 247px;
}