.TABLET {
  background-color: #ecf4ff;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  display: flex;
}

.TABLET .TABLET-wrapper {
  background-color: #ecf4ff;
  border: 1px;
  width: 834px;
  height: 2615px;
}

.TABLET .div {
  flex-direction: column;
  align-items: center;
  gap: 48px;
  width: -moz-fit-content;
  width: fit-content;
  height: 2615px;
  display: flex;
  position: relative;
}

.TABLET .HEADER {
  mix-blend-mode: normal;
  background-color: #ecf4ff;
  min-width: 832px;
  height: 128px;
  position: relative;
}

.TABLET .frame {
  align-items: center;
  gap: 230px;
  width: 769px;
  display: flex;
  position: relative;
  top: 33px;
  left: 33px;
}

.TABLET .LOGO {
  justify-content: center;
  align-items: center;
  gap: 32px;
  width: -moz-fit-content;
  width: fit-content;
  display: flex;
  position: relative;
}

.TABLET .logo-img {
  background-color: #24aee5;
  border-radius: 16px;
  min-width: 62px;
  height: 62px;
  position: relative;
}

.TABLET .overlap-group {
  background-image: url("vector-1-1.fe97a706.svg");
  background-size: 100% 100%;
  width: 37px;
  height: 35px;
  position: relative;
  top: 14px;
  left: 13px;
}

.TABLET .vector {
  width: 14px;
  height: 19px;
  position: absolute;
  top: 8px;
  left: 13px;
}

.TABLET .img {
  width: 4px;
  height: 3px;
  position: absolute;
  top: 13px;
  left: 18px;
}

.TABLET .logo-text {
  min-width: 262.74px;
  height: 49.85px;
  position: relative;
}

.TABLET .SART {
  justify-content: flex-end;
  align-items: flex-start;
  gap: 24px;
  width: -moz-fit-content;
  width: fit-content;
  margin-right: -.74px;
  display: flex;
  position: relative;
}

.TABLET .test-now-wrapper {
  background-color: #24aee5;
  border-radius: 16px;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
  width: -moz-fit-content;
  width: fit-content;
  padding: 16px 32px;
  display: flex;
  position: relative;
}

.TABLET .INTRO {
  background-color: #ecf4ff;
  min-width: 832px;
  height: 496px;
  position: relative;
  box-shadow: 1.37px 15.11px 21.3px -9.62px #00000040;
}

.TABLET .overlap {
  width: 812px;
  height: 477px;
  position: relative;
  top: 19px;
}

.TABLET .vector-2 {
  width: 423px;
  height: 363px;
  position: absolute;
  top: 0;
  left: 389px;
}

.TABLET .seniors-are {
  color: #000;
  letter-spacing: 0;
  width: 197px;
  height: 217px;
  font-family: Kumbh Sans, Helvetica;
  font-size: 24.7px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 47px;
  left: 569px;
}

.TABLET .div-wrapper {
  background-color: #24aee5;
  border-radius: 5.5px;
  align-items: flex-start;
  gap: 6.87px;
  width: -moz-fit-content;
  width: fit-content;
  padding: 6.87px 16.49px;
  display: flex;
  position: absolute;
  top: 290px;
  left: 569px;
}

.TABLET .text-wrapper {
  color: #f8fdff;
  letter-spacing: 0;
  text-align: center;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.69px;
  font-family: Kumbh Sans, Helvetica;
  font-size: 11px;
  font-weight: 700;
  line-height: normal;
  position: relative;
}

.TABLET .vector-3 {
  width: 502px;
  height: 359px;
  position: absolute;
  top: 118px;
  left: 0;
}

.TABLET .girl-teaching-her {
  object-fit: cover;
  width: 516px;
  height: 414px;
  position: absolute;
  top: 64px;
  left: 0;
}

.TABLET .p {
  color: #0f084b;
  letter-spacing: 0;
  width: -moz-fit-content;
  width: fit-content;
  font-family: Kumbh Sans, Helvetica;
  font-size: 32px;
  font-weight: 600;
  line-height: normal;
  position: relative;
}

.TABLET .STEP {
  justify-content: center;
  align-items: center;
  gap: 60px;
  width: 834px;
  height: 321px;
  padding: 0 80px;
  display: flex;
  position: relative;
}

.TABLET .overlap-group-wrapper {
  border-radius: 16px;
  min-width: 275px;
  height: 274px;
  position: relative;
}

.TABLET .overlap-group-2 {
  width: 275px;
  height: 300px;
  position: relative;
  top: -25px;
}

.TABLET .rectangle {
  backdrop-filter: blur(40px) brightness();
  background: linear-gradient(#ffffff80 0%, #fff3 100%);
  border: 3px solid #0000;
  border-image: linear-gradient(#ffffffb3 .43%, #00b6ff00 54.17%, #00b6ff4a 100%) 1;
  border-radius: 16px;
  width: 275px;
  height: 275px;
  position: absolute;
  top: 25px;
  left: 0;
  box-shadow: 0 4px 24px -1px #00000040;
}

.TABLET .vector-4 {
  width: 118px;
  height: 91px;
  position: absolute;
  top: 78px;
  left: 77px;
}

.TABLET .text-wrapper-2 {
  color: #0d0221;
  letter-spacing: 0;
  text-align: center;
  width: 200px;
  height: 44px;
  font-family: Satoshi-Regular, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 202px;
  left: 36px;
}

.TABLET .vector-5 {
  width: 50px;
  height: 56px;
  position: absolute;
  top: 96px;
  left: 111px;
}

.TABLET .frame-2 {
  background: linear-gradient(#fff 0%, #ffffff63 100%);
  border: 2px solid #0000;
  border-image: linear-gradient(#ffffffb3 .43%, #00b6ff00 54.17%, #00b6ff4a 100%) 1;
  border-radius: 16px;
  align-items: flex-start;
  gap: 10px;
  width: -moz-fit-content;
  width: fit-content;
  padding: 10px;
  display: flex;
  position: absolute;
  top: 0;
  left: 93px;
}

.TABLET .text-wrapper-3 {
  color: #000;
  letter-spacing: 0;
  text-align: center;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -2px;
  font-family: Kumbh Sans, Helvetica;
  font-size: 24px;
  font-weight: 400;
  line-height: normal;
  position: relative;
}

.TABLET .STEP-2 {
  backdrop-filter: blur(40px) brightness();
  background: linear-gradient(#ffffff80 0%, #fff3 100%);
  border-radius: 16px;
  min-width: 275px;
  height: 275px;
  position: relative;
  box-shadow: 0 4px 24px -1px #00000040;
}

.TABLET .text-wrapper-4 {
  color: #0d0221;
  letter-spacing: 0;
  text-align: center;
  width: 200px;
  height: 44px;
  font-family: Satoshi-Regular, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 178px;
  left: 37px;
}

.TABLET .vector-wrapper {
  background-image: url("vector-12-1.0036bf9a.svg");
  background-size: 100% 100%;
  width: 126px;
  height: 98px;
  position: absolute;
  top: 48px;
  left: 73px;
}

.TABLET .vector-6 {
  width: 56px;
  height: 56px;
  position: absolute;
  top: 24px;
  left: 36px;
}

.TABLET .frame-3 {
  background: linear-gradient(#fff 0%, #ffffff63 100%);
  border: 2px solid #0000;
  border-image: linear-gradient(#ffffffb3 .43%, #00b6ff00 54.17%, #00b6ff4a 100%) 1;
  border-radius: 16px;
  align-items: flex-start;
  gap: 10px;
  width: -moz-fit-content;
  width: fit-content;
  padding: 10px;
  display: flex;
  position: absolute;
  top: -25px;
  left: 91px;
}

.TABLET .text-wrapper-5 {
  color: #000;
  letter-spacing: 0;
  text-align: center;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -2px;
  font-family: Kumbh Sans, Helvetica;
  font-size: 24px;
  font-weight: 400;
  line-height: normal;
  position: relative;
}

.TABLET .STEP-3 {
  justify-content: center;
  align-items: center;
  gap: 60px;
  width: 834px;
  height: 321px;
  padding: 0 80px;
  display: flex;
  position: relative;
}

.TABLET .STEP-4 {
  backdrop-filter: blur(40px) brightness();
  background: linear-gradient(#ffffff80 0%, #fff3 100%);
  border-radius: 16px;
  min-width: 275px;
  height: 275px;
  position: relative;
  box-shadow: 0 4px 24px -1px #00000040;
}

.TABLET .text-wrapper-6 {
  color: #0d0221;
  letter-spacing: 0;
  text-align: center;
  width: 200px;
  height: 88px;
  font-family: Satoshi-Regular, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 166px;
  left: 38px;
}

.TABLET .frame-4 {
  background: linear-gradient(#fff 0%, #ffffff63 100%);
  border: 2px solid #0000;
  border-image: linear-gradient(#ffffffb3 .43%, #00b6ff00 54.17%, #00b6ff4a 100%) 1;
  border-radius: 16px;
  align-items: flex-start;
  gap: 10px;
  width: -moz-fit-content;
  width: fit-content;
  padding: 10px;
  display: flex;
  position: absolute;
  top: -25px;
  left: 93px;
}

.TABLET .text-wrapper-7 {
  color: #000;
  letter-spacing: 0;
  text-align: center;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -2px;
  font-family: Kumbh Sans, Helvetica;
  font-size: 24px;
  font-weight: 400;
  line-height: normal;
  position: relative;
}

.TABLET .overlap-group-3 {
  background-image: url("vector-13-1.14d32836.svg");
  background-size: 100% 100%;
  width: 124px;
  height: 99px;
  position: absolute;
  top: 49px;
  left: 76px;
}

.TABLET .vector-7 {
  width: 37px;
  height: 32px;
  position: absolute;
  top: 15px;
  left: 16px;
}

.TABLET .vector-8 {
  width: 28px;
  height: 44px;
  position: absolute;
  top: 40px;
  left: 61px;
}

.TABLET .overlap-wrapper {
  border-radius: 16px;
  min-width: 275px;
  height: 274px;
  position: relative;
}

.TABLET .overlap-2 {
  width: 275px;
  height: 300px;
  position: relative;
  top: -25px;
}

.TABLET .rectangle-2 {
  backdrop-filter: blur(40px) brightness();
  background: linear-gradient(#ffffff80 0%, #fff3 100%);
  border: 3px solid #0000;
  border-image: linear-gradient(#ffffffb3 .43%, #00b6ff00 54.17%, #00b6ff4a 100%) 1;
  border-radius: 16px;
  width: 275px;
  height: 275px;
  position: absolute;
  top: 25px;
  left: 0;
  box-shadow: 0 4px 24px -1px #00000040;
}

.TABLET .text-wrapper-8 {
  color: #0d0221;
  letter-spacing: 0;
  text-align: center;
  width: 200px;
  height: 66px;
  font-family: Satoshi-Regular, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 203px;
  left: 39px;
}

.TABLET .frame-5 {
  background: linear-gradient(#fff 0%, #ffffff63 100%);
  border: 2px solid #0000;
  border-image: linear-gradient(#ffffffb3 .43%, #00b6ff00 54.17%, #00b6ff4a 100%) 1;
  border-radius: 16px;
  align-items: flex-start;
  gap: 10px;
  width: -moz-fit-content;
  width: fit-content;
  padding: 10px;
  display: flex;
  position: absolute;
  top: 0;
  left: 92px;
}

.TABLET .text-wrapper-9 {
  color: #000;
  letter-spacing: 0;
  text-align: center;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -2px;
  font-family: Kumbh Sans, Helvetica;
  font-size: 24px;
  font-weight: 400;
  line-height: normal;
  position: relative;
}

.TABLET .vector-9 {
  width: 124px;
  height: 99px;
  position: absolute;
  top: 78px;
  left: 76px;
}

.TABLET .vector-10 {
  width: 56px;
  height: 50px;
  position: absolute;
  top: 102px;
  left: 109px;
}

.TABLET .INFO {
  align-items: center;
  gap: 80px;
  width: 727px;
  display: flex;
  position: relative;
}

.TABLET .frame-6 {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 48px;
  width: -moz-fit-content;
  width: fit-content;
  display: flex;
  position: relative;
}

.TABLET .h-1 {
  color: #0d0221;
  letter-spacing: 0;
  width: 615px;
  margin-top: -1px;
  font-family: Satoshi-Regular, Helvetica;
  font-size: 36px;
  font-weight: 400;
  line-height: normal;
  position: relative;
}

.TABLET .frame-7 {
  align-items: flex-start;
  gap: 48px;
  width: 727px;
  display: flex;
  position: relative;
}

.TABLET .frame-8 {
  background: linear-gradient(#ffffff80 0%, #fff3 100%);
  border: 2px solid #0000;
  border-image: linear-gradient(#ffffffb3 .43%, #00b6ff00 44.79%, #00b6ff4a 100%) 1;
  border-radius: 11.56px;
  align-items: center;
  gap: 7.22px;
  width: 335px;
  height: 57px;
  padding: 17.33px 32px;
  display: flex;
  position: relative;
  box-shadow: 2px 2px 8px 2px #00000040;
}

.TABLET .text-wrapper-10 {
  color: #26408b;
  letter-spacing: 0;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -1.83px;
  font-family: Lexend, Helvetica;
  font-size: 17.3px;
  font-weight: 400;
  line-height: normal;
  position: relative;
}

.TABLET .frame-9 {
  background: linear-gradient(#ffffff80 0%, #fff3 100%);
  border: 2px solid #0000;
  border-image: linear-gradient(#ffffffb3 .43%, #00b6ff00 44.79%, #00b6ff4a 100%) 1;
  border-radius: 11.56px;
  align-items: center;
  gap: 7.22px;
  width: 344px;
  padding: 17.33px 32px;
  display: flex;
  position: relative;
  box-shadow: 2px 2px 8px 2px #00000040;
}

.TABLET .text-wrapper-11 {
  color: #26408b;
  letter-spacing: 0;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -2px;
  font-family: Lexend, Helvetica;
  font-size: 17.3px;
  font-weight: 400;
  line-height: normal;
  position: relative;
}

.TABLET .CALENDAR {
  align-items: center;
  gap: 80px;
  width: -moz-fit-content;
  width: fit-content;
  display: flex;
  position: relative;
}

.TABLET .component {
  min-width: 509px;
  height: 490.43px;
  margin-top: -27px;
  margin-bottom: -31px;
  margin-left: -27px;
  position: relative;
}

.TABLET .frame-10 {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 17.33px;
  width: -moz-fit-content;
  width: fit-content;
  height: 432px;
  display: flex;
  position: relative;
}

.TABLET .vector-11 {
  min-width: 32.38px;
  height: 18.23px;
  position: relative;
}

.TABLET .frame-11 {
  background: linear-gradient(#ffffff80 0%, #fff3 100%);
  border: 2px solid #0000;
  border-image: linear-gradient(#ffffffb3 .43%, #00b6ff00 44.79%, #00b6ff4a 100%) 1;
  border-radius: 11.56px;
  justify-content: center;
  align-items: center;
  gap: 7.22px;
  width: 196px;
  padding: 17.33px 192.11px;
  display: flex;
  position: relative;
  box-shadow: 2px 2px 8px 2px #00000040;
}

.TABLET .text-wrapper-12 {
  color: #26408b;
  letter-spacing: 0;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -2px;
  margin-left: -116.11px;
  margin-right: -116.11px;
  font-family: Lexend, Helvetica;
  font-size: 17.3px;
  font-weight: 400;
  line-height: normal;
  position: relative;
}

.TABLET .frame-12 {
  background: linear-gradient(#ffffff80 0%, #fff3 100%);
  border: 2px solid #0000;
  border-image: linear-gradient(#ffffffb3 .43%, #00b6ff00 44.79%, #00b6ff4a 100%) 1;
  border-radius: 11.56px;
  justify-content: center;
  align-items: center;
  gap: 7.22px;
  width: 196px;
  padding: 17.33px 192.11px;
  display: flex;
  position: relative;
  box-shadow: 2px 2px 8px 2px #00000040;
}

.TABLET .text-wrapper-13 {
  color: #26408b;
  letter-spacing: 0;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -2px;
  margin-left: -115.61px;
  margin-right: -115.61px;
  font-family: Lexend, Helvetica;
  font-size: 17.3px;
  font-weight: 400;
  line-height: normal;
  position: relative;
}

.TABLET .frame-13 {
  background: linear-gradient(#ffffff80 0%, #fff3 100%);
  border: 2px solid #0000;
  border-image: linear-gradient(#ffffffb3 .43%, #00b6ff00 44.79%, #00b6ff4a 100%) 1;
  border-radius: 11.56px;
  justify-content: center;
  align-items: center;
  gap: 7.22px;
  width: 196px;
  padding: 17.33px 192.11px;
  display: flex;
  position: relative;
  box-shadow: 2px 2px 8px 2px #00000040;
}

.TABLET .text-wrapper-14 {
  color: #26408b;
  letter-spacing: 0;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -2px;
  margin-left: -115.61px;
  margin-right: -115.61px;
  font-family: Lexend, Helvetica;
  font-size: 17.3px;
  font-weight: 400;
  line-height: normal;
  position: relative;
}

.TABLET .frame-14 {
  background: linear-gradient(#ffffff80 0%, #fff3 100%);
  border: 2px solid #0000;
  border-image: linear-gradient(#ffffffb3 .43%, #00b6ff00 44.79%, #00b6ff4a 100%) 1;
  border-radius: 11.56px;
  justify-content: center;
  align-items: center;
  gap: 7.22px;
  width: 194px;
  padding: 17.33px 192.11px;
  display: flex;
  position: relative;
  box-shadow: 2px 2px 8px 2px #00000040;
}

.TABLET .text-wrapper-15 {
  color: #26408b;
  letter-spacing: 0;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -2px;
  margin-left: -115.61px;
  margin-right: -115.61px;
  font-family: Lexend, Helvetica;
  font-size: 17.3px;
  font-weight: 400;
  line-height: normal;
  position: relative;
}

.TABLET .frame-15 {
  background: linear-gradient(#ffffff80 0%, #fff3 100%);
  border: 2px solid #0000;
  border-image: linear-gradient(#ffffffb3 6.85%, #00b6ff00 44.79%, #00b6ff4a 100%) 1;
  border-radius: 11.56px;
  justify-content: center;
  align-items: center;
  gap: 7.22px;
  width: 194px;
  padding: 17.33px 192.11px;
  display: flex;
  position: relative;
  box-shadow: 2px 2px 8px 2px #00000040;
}

.TABLET .text-wrapper-16 {
  color: #26408b;
  letter-spacing: 0;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -2px;
  margin-left: -115.61px;
  margin-right: -115.61px;
  font-family: Lexend, Helvetica;
  font-size: 17.3px;
  font-weight: 400;
  line-height: normal;
  position: relative;
}

.TABLET .vector-12 {
  min-width: 32.38px;
  height: 18.23px;
  position: relative;
}

.TABLET .START {
  background-color: #24aee5;
  border-radius: 16px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 194px;
  padding: 16px 32px;
  display: flex;
  position: relative;
}

.TABLET .test-now {
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -1px;
  font-family: Kumbh Sans, Helvetica;
  font-size: 16px;
  font-weight: 700;
  line-height: normal;
  position: relative;
}

.TABLET .FOOTER {
  background-color: #0000;
  flex-direction: column;
  align-items: center;
  gap: 32px;
  width: -moz-fit-content;
  width: fit-content;
  height: auto;
  display: flex;
  position: relative;
}

.TABLET .SN {
  flex-direction: column;
  align-items: center;
  gap: 23px;
  width: -moz-fit-content;
  width: fit-content;
  display: flex;
  position: relative;
}

.TABLET .text-wrapper-17 {
  color: #0d0221;
  letter-spacing: 0;
  text-align: center;
  width: 473px;
  margin-top: -1px;
  font-family: Satoshi-Regular, Helvetica;
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
  position: relative;
}

.TABLET .frame-16 {
  align-items: center;
  gap: 16px;
  width: -moz-fit-content;
  width: fit-content;
  display: flex;
  position: relative;
}

.TABLET .frame-17 {
  flex-direction: column;
  align-items: center;
  gap: 8px;
  width: 69px;
  display: flex;
  position: relative;
}

.TABLET .facebook-rounded {
  background-image: url("container.dc5ea7fd.svg");
  background-size: 100% 100%;
  min-width: 40px;
  height: 40px;
  position: relative;
}

.TABLET .text-wrapper-18 {
  color: #0d0221;
  letter-spacing: 0;
  width: -moz-fit-content;
  width: fit-content;
  font-family: Satoshi-Regular, Helvetica;
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
  position: relative;
}

.TABLET .frame-18 {
  flex-direction: column;
  align-items: center;
  gap: 8px;
  width: 78px;
  display: flex;
  position: relative;
}

.TABLET .instagram-rounded {
  background-image: url("container-1.b89350dd.svg");
  background-size: 100% 100%;
  min-width: 39.98px;
  height: 40px;
  position: relative;
}

.TABLET .text-wrapper-19 {
  color: #0d0221;
  letter-spacing: 0;
  width: -moz-fit-content;
  width: fit-content;
  font-family: Satoshi-Regular, Helvetica;
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
  position: relative;
}

.TABLET .frame-19 {
  flex-direction: column;
  align-items: center;
  gap: 8px;
  width: 78px;
  display: flex;
  position: relative;
}

.TABLET .linkedin-rounded {
  background-image: url("container-2.314aae50.svg");
  background-size: 100% 100%;
  min-width: 40px;
  height: 40px;
  position: relative;
}

.TABLET .text-wrapper-20 {
  color: #0d0221;
  letter-spacing: 0;
  width: -moz-fit-content;
  width: fit-content;
  font-family: Satoshi-Regular, Helvetica;
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
  position: relative;
}

.TABLET .frame-20 {
  flex-direction: column;
  align-items: center;
  gap: 8px;
  width: 78px;
  display: flex;
  position: relative;
}

.TABLET .youtube-rounded {
  background-image: url("container-3.c19df1e7.svg");
  background-size: 100% 100%;
  min-width: 40px;
  height: 40px;
  position: relative;
}

.TABLET .text-wrapper-21 {
  color: #0d0221;
  letter-spacing: 0;
  width: -moz-fit-content;
  width: fit-content;
  font-family: Satoshi-Regular, Helvetica;
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
  position: relative;
}

.TABLET .frame-21 {
  flex-direction: column;
  align-items: center;
  gap: 8px;
  width: 78px;
  display: flex;
  position: relative;
}

.TABLET .whatsapp-rounded {
  background-image: url("container-4.1afe1fff.svg");
  background-size: 100% 100%;
  min-width: 40px;
  height: 40px;
  position: relative;
}

.TABLET .text-wrapper-22 {
  color: #0d0221;
  letter-spacing: 0;
  width: -moz-fit-content;
  width: fit-content;
  font-family: Satoshi-Regular, Helvetica;
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
  position: relative;
}

.TABLET .COPYRIGHT {
  align-items: center;
  gap: 16px;
  width: -moz-fit-content;
  width: fit-content;
  height: 48px;
  display: flex;
  position: relative;
}

.TABLET .logo-img-wrapper {
  min-width: 48px;
  height: 48px;
  position: relative;
}

.TABLET .logo-img-2 {
  background-color: #24aee5;
  border-radius: 16px;
  width: 48px;
  height: 48px;
}

.TABLET .overlap-group-4 {
  background-image: url("vector-1-2.f53ed74c.svg");
  background-size: 100% 100%;
  width: 29px;
  height: 27px;
  position: relative;
  top: 11px;
  left: 10px;
}

.TABLET .vector-13 {
  width: 11px;
  height: 15px;
  position: absolute;
  top: 6px;
  left: 10px;
}

.TABLET .vector-14 {
  width: 3px;
  height: 3px;
  position: absolute;
  top: 10px;
  left: 14px;
}

.TABLET .frame-22 {
  align-items: center;
  gap: 8px;
  width: -moz-fit-content;
  width: fit-content;
  display: flex;
  position: relative;
}

.TABLET .group {
  min-width: 16px;
  height: 16px;
  position: relative;
}

.TABLET .text-wrapper-23 {
  color: #0d0221;
  letter-spacing: 0;
  width: 247px;
  margin-top: -1px;
  font-family: Satoshi-Regular, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  position: relative;
}

.WEB {
  background-color: #ecf4ff;
  border: 1px;
  flex-direction: column;
  align-items: center;
  gap: 48px;
  min-width: 1440px;
  display: flex;
  position: relative;
}

.WEB .HEADER {
  mix-blend-mode: normal;
  background-color: #ecf4ff;
  min-width: 1440px;
  height: 128px;
  position: relative;
}

.WEB .frame {
  justify-content: space-between;
  align-items: center;
  width: 1281px;
  display: flex;
  position: relative;
  top: 33px;
  left: 80px;
}

.WEB .logo {
  justify-content: center;
  align-items: center;
  gap: 32px;
  width: -moz-fit-content;
  width: fit-content;
  display: flex;
  position: relative;
}

.WEB .logo-img {
  background-color: #24aee5;
  border-radius: 16px;
  min-width: 62px;
  height: 62px;
  position: relative;
}

.WEB .overlap-group {
  background-image: url("vector-1-1.fe97a706.svg");
  background-size: 100% 100%;
  width: 37px;
  height: 35px;
  position: relative;
  top: 14px;
  left: 13px;
}

.WEB .vector {
  width: 14px;
  height: 19px;
  position: absolute;
  top: 8px;
  left: 13px;
}

.WEB .img {
  width: 4px;
  height: 3px;
  position: absolute;
  top: 13px;
  left: 18px;
}

.WEB .logo-text {
  min-width: 262.74px;
  height: 49.85px;
  position: relative;
}

.WEB .menu {
  justify-content: flex-end;
  align-items: flex-start;
  gap: 24px;
  width: -moz-fit-content;
  width: fit-content;
  display: flex;
  position: relative;
}

.WEB .div-wrapper {
  border-radius: 16px;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
  width: -moz-fit-content;
  width: fit-content;
  padding: 16px 32px;
  display: flex;
  position: relative;
}

.WEB .text-wrapper {
  color: #0f084b;
  letter-spacing: 0;
  text-align: center;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -1px;
  font-family: Kumbh Sans, Helvetica;
  font-size: 16px;
  font-weight: 600;
  line-height: normal;
  position: relative;
}

.WEB .div {
  background-color: #24aee5;
  border-radius: 16px;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
  width: -moz-fit-content;
  width: fit-content;
  padding: 16px 32px;
  display: flex;
  position: relative;
}

.WEB .text-wrapper-2 {
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -1px;
  font-family: Kumbh Sans, Helvetica;
  font-size: 16px;
  font-weight: 600;
  line-height: normal;
  position: relative;
}

.WEB .INTRO {
  background-color: #ecf4ff;
  min-width: 1440px;
  height: 722px;
  position: relative;
  box-shadow: 2px 22px 31px -14px #00000040;
}

.WEB .overlap {
  width: 1364px;
  height: 668px;
  position: relative;
  top: 54px;
}

.WEB .vector-2 {
  width: 629px;
  height: 557px;
  position: absolute;
  top: 0;
  left: 735px;
}

.WEB .seniors-are {
  color: #000;
  letter-spacing: 0;
  width: 312px;
  height: 315px;
  font-family: Kumbh Sans, Helvetica;
  font-size: 36px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 100px;
  left: 983px;
}

.WEB .frame-2 {
  background-color: #24aee5;
  border-radius: 8px;
  align-items: flex-start;
  gap: 10px;
  width: -moz-fit-content;
  width: fit-content;
  padding: 10px 24px;
  display: flex;
  position: absolute;
  top: 480px;
  left: 983px;
}

.WEB .text-wrapper-3 {
  color: #f8fdff;
  letter-spacing: 0;
  text-align: center;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -1px;
  font-family: Kumbh Sans, Helvetica;
  font-size: 16px;
  font-weight: 700;
  line-height: normal;
  position: relative;
}

.WEB .vector-3 {
  width: 867px;
  height: 522px;
  position: absolute;
  top: 146px;
  left: 0;
}

.WEB .girl-teaching-her {
  object-fit: cover;
  width: 887px;
  height: 602px;
  position: absolute;
  top: 66px;
  left: 0;
}

.WEB .h-1 {
  color: #0f084b;
  letter-spacing: 0;
  width: -moz-fit-content;
  width: fit-content;
  font-family: Kumbh Sans, Helvetica;
  font-size: 36px;
  font-weight: 600;
  line-height: normal;
  position: relative;
}

.WEB .STEPS {
  align-items: center;
  gap: 60px;
  width: 1440px;
  height: 321px;
  padding: 0 80px;
  display: flex;
  position: relative;
}

.WEB .STEP {
  border-radius: 16px;
  min-width: 275px;
  height: 274px;
  position: relative;
}

.WEB .overlap-group-2 {
  width: 275px;
  height: 300px;
  position: relative;
  top: -25px;
}

.WEB .rectangle {
  backdrop-filter: blur(40px) brightness();
  background: linear-gradient(#ffffff80 0%, #fff3 100%);
  border: 3px solid #0000;
  border-image: linear-gradient(#ffffffb3 .43%, #00b6ff00 54.17%, #00b6ff4a 100%) 1;
  border-radius: 16px;
  width: 275px;
  height: 275px;
  position: absolute;
  top: 25px;
  left: 0;
  box-shadow: 0 4px 24px -1px #00000040;
}

.WEB .vector-4 {
  width: 118px;
  height: 91px;
  position: absolute;
  top: 78px;
  left: 77px;
}

.WEB .p {
  color: #0d0221;
  letter-spacing: 0;
  text-align: center;
  width: 200px;
  height: 44px;
  font-family: Satoshi-Regular, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 202px;
  left: 36px;
}

.WEB .vector-5 {
  width: 50px;
  height: 56px;
  position: absolute;
  top: 96px;
  left: 111px;
}

.WEB .frame-3 {
  background: linear-gradient(#fff 0%, #ffffff63 100%);
  border: 2px solid #0000;
  border-image: linear-gradient(#ffffffb3 .43%, #00b6ff00 54.17%, #00b6ff4a 100%) 1;
  border-radius: 16px;
  align-items: flex-start;
  gap: 10px;
  width: -moz-fit-content;
  width: fit-content;
  padding: 10px;
  display: flex;
  position: absolute;
  top: 0;
  left: 93px;
}

.WEB .text-wrapper-4 {
  color: #000;
  letter-spacing: 0;
  text-align: center;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -2px;
  font-family: Kumbh Sans, Helvetica;
  font-size: 24px;
  font-weight: 400;
  line-height: normal;
  position: relative;
}

.WEB .STEP-2 {
  backdrop-filter: blur(40px) brightness();
  background: linear-gradient(#ffffff80 0%, #fff3 100%);
  border-radius: 16px;
  min-width: 275px;
  height: 275px;
  position: relative;
  box-shadow: 0 4px 24px -1px #00000040;
}

.WEB .text-wrapper-5 {
  color: #0d0221;
  letter-spacing: 0;
  text-align: center;
  width: 200px;
  height: 44px;
  font-family: Satoshi-Regular, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 178px;
  left: 37px;
}

.WEB .vector-wrapper {
  background-image: url("vector-12.72ca48cd.svg");
  background-size: 100% 100%;
  width: 126px;
  height: 98px;
  position: absolute;
  top: 48px;
  left: 73px;
}

.WEB .vector-6 {
  width: 56px;
  height: 56px;
  position: absolute;
  top: 24px;
  left: 36px;
}

.WEB .frame-4 {
  background: linear-gradient(#fff 0%, #ffffff63 100%);
  border: 2px solid #0000;
  border-image: linear-gradient(#ffffffb3 .43%, #00b6ff00 54.17%, #00b6ff4a 100%) 1;
  border-radius: 16px;
  align-items: flex-start;
  gap: 10px;
  width: -moz-fit-content;
  width: fit-content;
  padding: 10px;
  display: flex;
  position: absolute;
  top: -25px;
  left: 91px;
}

.WEB .text-wrapper-6 {
  color: #0d0221;
  letter-spacing: 0;
  text-align: center;
  width: 200px;
  height: 66px;
  font-family: Satoshi-Regular, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 178px;
  left: 38px;
}

.WEB .frame-5 {
  background: linear-gradient(#fff 0%, #ffffff63 100%);
  border: 2px solid #0000;
  border-image: linear-gradient(#ffffffb3 .43%, #00b6ff00 54.17%, #00b6ff4a 100%) 1;
  border-radius: 16px;
  align-items: flex-start;
  gap: 10px;
  width: -moz-fit-content;
  width: fit-content;
  padding: 10px;
  display: flex;
  position: absolute;
  top: -25px;
  left: 93px;
}

.WEB .overlap-2 {
  background-image: url("vector-13-1.14d32836.svg");
  background-size: 100% 100%;
  width: 124px;
  height: 99px;
  position: absolute;
  top: 49px;
  left: 76px;
}

.WEB .vector-7 {
  width: 37px;
  height: 32px;
  position: absolute;
  top: 15px;
  left: 16px;
}

.WEB .vector-8 {
  width: 28px;
  height: 44px;
  position: absolute;
  top: 40px;
  left: 61px;
}

.WEB .text-wrapper-7 {
  color: #0d0221;
  letter-spacing: 0;
  text-align: center;
  width: 200px;
  height: 66px;
  font-family: Satoshi-Regular, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 203px;
  left: 39px;
}

.WEB .frame-6 {
  background: linear-gradient(#fff 0%, #ffffff63 100%);
  border: 2px solid #0000;
  border-image: linear-gradient(#ffffffb3 .43%, #00b6ff00 54.17%, #00b6ff4a 100%) 1;
  border-radius: 16px;
  align-items: flex-start;
  gap: 10px;
  width: -moz-fit-content;
  width: fit-content;
  padding: 10px;
  display: flex;
  position: absolute;
  top: 0;
  left: 92px;
}

.WEB .vector-9 {
  width: 124px;
  height: 99px;
  position: absolute;
  top: 78px;
  left: 76px;
}

.WEB .vector-10 {
  width: 56px;
  height: 50px;
  position: absolute;
  top: 102px;
  left: 109px;
}

.WEB .CALENDAR {
  justify-content: center;
  align-items: center;
  gap: 48px;
  width: 1440px;
  height: 600px;
  display: flex;
  position: relative;
}

.WEB .frame-7 {
  align-items: center;
  gap: 80px;
  width: -moz-fit-content;
  width: fit-content;
  display: flex;
  position: relative;
}

.WEB .component {
  min-width: 509px;
  height: 490.43px;
  margin-top: -4.25px;
  margin-bottom: -8.25px;
  margin-left: -27px;
  position: relative;
}

.WEB .HOURS {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 17.33px;
  width: -moz-fit-content;
  width: fit-content;
  height: 432px;
  display: flex;
  position: relative;
}

.WEB .vector-11 {
  min-width: 32.38px;
  height: 18.23px;
  position: relative;
}

.WEB .frame-8 {
  background: linear-gradient(#ffffff80 0%, #fff3 100%);
  border: 2px solid #0000;
  border-image: linear-gradient(#ffffffb3 .43%, #00b6ff00 44.79%, #00b6ff4a 100%) 1;
  border-radius: 11.56px;
  justify-content: center;
  align-items: center;
  gap: 7.22px;
  width: 196px;
  padding: 17.33px 192.11px;
  display: flex;
  position: relative;
  box-shadow: 2px 2px 8px 2px #00000040;
}

.WEB .text-wrapper-8 {
  color: #26408b;
  letter-spacing: 0;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -2px;
  margin-left: -116.11px;
  margin-right: -116.11px;
  font-family: Lexend, Helvetica;
  font-size: 17.3px;
  font-weight: 400;
  line-height: normal;
  position: relative;
}

.WEB .text-wrapper-9 {
  color: #26408b;
  letter-spacing: 0;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -2px;
  margin-left: -115.61px;
  margin-right: -115.61px;
  font-family: Lexend, Helvetica;
  font-size: 17.3px;
  font-weight: 400;
  line-height: normal;
  position: relative;
}

.WEB .frame-9 {
  background: linear-gradient(#ffffff80 0%, #fff3 100%);
  border: 2px solid #0000;
  border-image: linear-gradient(#ffffffb3 .43%, #00b6ff00 44.79%, #00b6ff4a 100%) 1;
  border-radius: 11.56px;
  justify-content: center;
  align-items: center;
  gap: 7.22px;
  width: 194px;
  padding: 17.33px 192.11px;
  display: flex;
  position: relative;
  box-shadow: 2px 2px 8px 2px #00000040;
}

.WEB .frame-10 {
  background: linear-gradient(#ffffff80 0%, #fff3 100%);
  border: 2px solid #0000;
  border-image: linear-gradient(#ffffffb3 6.85%, #00b6ff00 44.79%, #00b6ff4a 100%) 1;
  border-radius: 11.56px;
  justify-content: center;
  align-items: center;
  gap: 7.22px;
  width: 194px;
  padding: 17.33px 192.11px;
  display: flex;
  position: relative;
  box-shadow: 2px 2px 8px 2px #00000040;
}

.WEB .INFO {
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 32px;
  width: -moz-fit-content;
  width: fit-content;
  display: flex;
  position: relative;
}

.WEB .group {
  min-width: 122.07px;
  height: 86.6px;
  position: relative;
}

.WEB .overlap-group-3 {
  width: 121px;
  height: 84px;
  position: relative;
  top: 1px;
}

.WEB .vector-12 {
  width: 88px;
  height: 84px;
  position: absolute;
  top: 0;
  left: 33px;
}

.WEB .frame-11 {
  background-color: #fff;
  border: 2px solid #0000;
  border-image: linear-gradient(#ffffffb3 .43%, #00b6ff00 54.17%, #00b6ff4a 100%) 1;
  border-radius: 16px;
  align-items: flex-start;
  gap: 10px;
  width: -moz-fit-content;
  width: fit-content;
  padding: 10px;
  display: flex;
  position: absolute;
  top: 8px;
  left: 0;
}

.WEB .text-wrapper-10 {
  color: #0d0221;
  letter-spacing: 0;
  width: 481px;
  font-family: Satoshi-Regular, Helvetica;
  font-size: 36px;
  font-weight: 400;
  line-height: normal;
  position: relative;
}

.WEB .frame-12 {
  background: linear-gradient(#ffffff80 0%, #fff3 100%);
  border: 2px solid #0000;
  border-image: linear-gradient(#ffffffb3 .43%, #00b6ff00 44.79%, #00b6ff4a 100%) 1;
  border-radius: 11.56px;
  align-items: center;
  gap: 7.22px;
  width: 474px;
  padding: 17.33px 32px;
  display: flex;
  position: relative;
  box-shadow: 2px 2px 8px 2px #00000040;
}

.WEB .text-wrapper-11 {
  color: #26408b;
  letter-spacing: 0;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -2px;
  font-family: Lexend, Helvetica;
  font-size: 17.3px;
  font-weight: 400;
  line-height: normal;
  position: relative;
}

.WEB .next-step {
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -1px;
  font-family: Kumbh Sans, Helvetica;
  font-size: 16px;
  font-weight: 700;
  line-height: normal;
  position: relative;
}

.WEB .FOOTER {
  mix-blend-mode: normal;
  background-color: #ecf4ff;
  min-width: 1440px;
  height: 176px;
  position: relative;
}

.WEB .frame-13 {
  align-items: center;
  gap: 475px;
  width: 1280px;
  display: flex;
  position: relative;
  top: 33px;
  left: 80px;
}

.WEB .COPYRIGHT-wrapper {
  align-items: center;
  width: -moz-fit-content;
  width: fit-content;
  display: flex;
  position: relative;
}

.WEB .COPYRIGHT {
  align-items: center;
  gap: 16px;
  width: -moz-fit-content;
  width: fit-content;
  height: 48px;
  display: flex;
  position: relative;
}

.WEB .image {
  object-fit: cover;
  min-width: 137px;
  height: 97px;
  margin-top: -24.5px;
  margin-bottom: -24.5px;
  position: relative;
}

.WEB .frame-14 {
  align-items: center;
  gap: 8px;
  width: -moz-fit-content;
  width: fit-content;
  display: flex;
  position: relative;
}

.WEB .group-2 {
  min-width: 16px;
  height: 16px;
  position: relative;
}

.WEB .digiplumber-s-l {
  color: #0d0221;
  letter-spacing: 0;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -1px;
  font-family: Satoshi-Regular, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  position: relative;
}

.WEB .SN {
  flex-direction: column;
  align-items: flex-start;
  gap: 23px;
  width: -moz-fit-content;
  width: fit-content;
  display: flex;
  position: relative;
}

.WEB .text-wrapper-12 {
  color: #0d0221;
  letter-spacing: 0;
  width: 473px;
  margin-top: -1px;
  font-family: Satoshi-Regular, Helvetica;
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
  position: relative;
}

.WEB .frame-15 {
  align-items: center;
  gap: 16px;
  width: -moz-fit-content;
  width: fit-content;
  display: flex;
  position: relative;
}

.WEB .frame-16 {
  flex-direction: column;
  align-items: center;
  gap: 8px;
  width: 69px;
  display: flex;
  position: relative;
}

.WEB .facebook-rounded {
  background-image: url("container-4.1afe1fff.svg");
  background-size: 100% 100%;
  min-width: 40px;
  height: 40px;
  position: relative;
}

.WEB .text-wrapper-13 {
  color: #0d0221;
  letter-spacing: 0;
  width: -moz-fit-content;
  width: fit-content;
  font-family: Satoshi-Regular, Helvetica;
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
  position: relative;
}

.WEB .frame-17 {
  flex-direction: column;
  align-items: center;
  gap: 8px;
  width: 78px;
  display: flex;
  position: relative;
}

.WEB .instagram-rounded {
  background-image: url("container-3.c19df1e7.svg");
  background-size: 100% 100%;
  min-width: 39.98px;
  height: 40px;
  position: relative;
}

.WEB .linkedin-rounded {
  background-image: url("container-2.314aae50.svg");
  background-size: 100% 100%;
  min-width: 40px;
  height: 40px;
  position: relative;
}

.WEB .youtube-rounded {
  background-image: url("container-1.b89350dd.svg");
  background-size: 100% 100%;
  min-width: 40px;
  height: 40px;
  position: relative;
}

.WEB .whatsapp-rounded {
  background-image: url("container.dc5ea7fd.svg");
  background-size: 100% 100%;
  min-width: 40px;
  height: 40px;
  position: relative;
}

/*# sourceMappingURL=index.8a04e38c.css.map */
